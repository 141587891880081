@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@lederne/config/colors.css';

@layer base {
  html {
    height: 100%;
  }

  html,
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-welcoming-green-800);
    color: var(--color-white);
    text-rendering: optimizeLegibility;
  }

  body {
    min-height: 100%;
    width: 100%;
    overflow-y: scroll;
    position: relative;
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

.quandrant-fill {
  fill: #9aafa4;
  transition: 700ms all cubic-bezier(0.4, 0, 0.2, 1) 1000ms;
}

.quandrant-fill-gradient-right {
  fill: url(#linear-gradient-right);
  transition: 700ms all cubic-bezier(0.4, 0, 0.2, 1) 1000ms;
}

.quandrant-fill-gradient-left {
  fill: url(#linear-gradient-left);
  transition: 700ms all cubic-bezier(0.4, 0, 0.2, 1) 1000ms;
}

.quandrant-fill-active {
  fill: #ede8e0;
  transition: 700ms all cubic-bezier(0.4, 0, 0.2, 1) 1000ms;
}
